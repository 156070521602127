<template>
    <div>
        <div class="page-top-box">
            <div class="page-title-box">
                <div class="go-back">
                    <el-button type="text" icon="el-icon-back" @click="goBack">返回</el-button>|
                </div>
                {{ $route.meta.title }}
            </div>
        </div>
        <raise-tab name="1"></raise-tab>
        <el-form
            :model="formData"
            ref="formData"
            label-position="top"
            v-loading="dataLoading"
            :disabled="true"
        >
            <RasieBaseInfo :formData.sync="formData" :reFresh="reFresh" :disabled="true"></RasieBaseInfo>
            <RaiseTicketPricesList :formData.sync="formData"  class="MT10" :disabled="true"></RaiseTicketPricesList>
        </el-form>
        <div class="page-group-box MT10">
            <el-button @click="goBack">返回</el-button>
        </div>
    </div>
</template>

<script>
import RaiseTab from './components/raise-tab.vue'
import RasieBaseInfo from './components/raise-baseInfo';
import RaiseTicketPricesList from './components/raise-ticketPrices-list';

const defaultFormData = {
    title:'',
    startTime:'',
    endTime:'',
    datetimerange:[],
    raiseType:1,
    raiseMoney:'',
    raiseModel:1,
    poster:'',
    posterOriginal:'',
    musicIds:[],
    introduce:'',
    raiseTicketPrices:[],
}
export default {
    props: {
        id: {
            type: [Number, String],
            default: null,
        },
    },
    components:{RaiseTab,RasieBaseInfo,RaiseTicketPricesList},
    data(){
        return{
            redirect: this.$route.query.redirect || '/manage/raise/list',
            dataLoading: false,
            btnLoading:false,
            formData:{...defaultFormData},
            reFresh:false
        }
    },
    mounted(){
        this.getDataDetail();
    },
    methods:{
        //获取详情
        getDataDetail() {
            this.dataLoading = true;
            this.$request({
                url: "/raise/info",
                params: { id: parseInt(this.id) },
                method: "post",
            })
                .then((res) => {
                    const { state, msg, result } = res.data;
                    if (state == 1) {
                        this.formData = {
                            ...defaultFormData,
                            ...result,
                            datetimerange:result.startTime ? [result.startTime,result.endTime || result.startTime] : [],
                            musicIds: result.musics ? result.musics.map( v => v.musicId) : [],
                            raiseMoney: result.raiseMoneyFormat || result.raiseMoney || ''
                        }; 
                    } else {
                        self.$message.error(msg || "获取信息失败,请重试");
                    }
                })
                .catch(() => {
                    this.$message.error("获取信息失败,请重试");
                })
                .finally(() => {
                    this.dataLoading = false;
                    this.reFresh = true;
                });
        },
        //返回
        goBack() {
            this.$router.push({
                path: this.redirect,
            });
        },
    }
}
</script>